cockpitApp.controller('seatMapController', [
  '$rootScope', '$scope', '$mdSidenav', '$filter', '$timeout', '$localStorage', 'domHelper', 'seatMap', 'seatMapHelper', 'functionalities', 'profile', 'logsProvider', 'profileHelper', 'basketHelper', 'segmentHelper', 'componentHelper', 'gettextCatalog',
  function($rootScope, $scope, $mdSidenav, $filter, $timeout, $localStorage, domHelper, seatMap, seatMapHelper, functionalities, profile, logsProvider, profileHelper, basketHelper, segmentHelper, componentHelper, gettextCatalog)
  {
    $scope.resetSeatMapSidenav = function() {
      if (!$scope.resetSelectedPassenger) {
        if (!$filter('isEmpty')($scope.passengerSeatList) && $scope.selectedPassengerPnrPosition) {
          $filter('filter')($scope.passengerSeatList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0].selected = false;
        }

        $scope.selectedPassengerPnrPosition = null;
      }

      $scope.allSeatsAlreadyReserved = false;
      $scope.removedBasketSeats = [];
      $scope.updatedBasketSeats = [];
      $scope.buttonState = 'add';
      $scope.deckSelectedId = 0;
      $scope.basketHelper = basketHelper;
      $scope.facilityCharacteristic = {};
      $scope.isDropdownOpen = false;
      $scope.isLastColumnAisle = false;
      $scope.isPassengerSeatPreselected = false;
      $scope.isSeatSelectedByOtherPassenger = false;
      $scope.isSliderShown = false;
      $scope.passengerSeatList = [];
      $scope.pnrHasInfants = false;
      $scope.resetSelectedPassenger = false;
      $scope.seatCharacteristicList = [];
      $scope.seatClicked = false;
      $scope.seatDeckList = [];
      $scope.selectedPassengerPnrPosition = null;
      $scope.seatMapScaling = $rootScope.isWebApp && $rootScope.isLargeScreen ? 0.8 : 1;
      $scope.ancillaryUnavailable = {
        message: gettextCatalog.getString('Seat reservation for your selected flight is not possible via login.'),
        imageNormal: '/img/moods/sidenavs/seat-map/unavailable.png',
        imageDouble: '/img/moods/sidenavs/seat-map/unavailable@2x.png',
        imageTriple: '/img/moods/sidenavs/seat-map/unavailable@3x.png',
      }
    };

    $scope.close = function() {
      $mdSidenav('seatMapMain').close();
      $scope.selectPassenger();
      $scope.seatClicked = false;
      $scope.selectedPassengerPnrPosition = null;

      if ($scope.seatMapWidget) {
        $scope.seatMapWidget.remove();
      }
    };

    $scope.$watch(function() {
      return $mdSidenav('seatMapMain').isOpen();
    }, function(newValue) {
      $rootScope.isSideNavOpen = newValue;

      if (!$rootScope.isSideNavOpen) {
        $scope.resetSeatMapSidenav();
      }
    });

    window.onChangeWidget = function(res) {
      const resJson = JSON.parse(res);
      $scope.status = resJson['status'];
      $scope.updateBasketSeatWidget(resJson['result']['seats']);
    }

    $scope.updateBasketSeatWidget = function (res){
      if ($localStorage.cockpit.baskets[$scope.activeFilekey] === undefined) {
        $localStorage.cockpit.baskets[$scope.activeFilekey] = {};
      }

      $localStorage.cockpit.baskets[$scope.activeFilekey].seats = res;
      $rootScope.$emit('refreshBasket', true);
      $rootScope.$apply();
    }

    $rootScope.$on('openSeatMap', function(event, seatMapSegments) {
      $scope.slidersPerPassengerType = {};
      $scope.seatMapSegments = seatMapSegments;
      $scope.data = seatMapSegments['currentSegment'];
      $scope.seatClicked = false;
      $scope.basket = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
      $scope.status = 'loading';

      if (document.getElementById('mcw-seatmaps')) {
        $scope.initSeatMapWidget();
      } else {
        $scope.initWrapper(seatMapSegments);
      }
    });

    $scope.seatMapWidget = null;

    $scope.initSeatMapWidget = function() {
      if ($scope.seatMapWidget) {
        $scope.seatMapWidget.remove();
      }
      $scope.seatMapWidget = document.createElement("mcw-seatmaps");
      $scope.seatMapWidget.setAttribute("filekey", $scope.activeFilekey);
      $scope.seatMapWidget.setAttribute("lastname", $scope.activeLastname);
      $scope.seatMapWidget.setAttribute("locale", $rootScope.activeLocale);
      $scope.seatMapWidget.setAttribute("segment-number", $scope.data.segmentExtendedNumber);
      $scope.seatMapWidget.setAttribute("basket-data", JSON.stringify($scope.basket));
      $scope.seatMapWidget.setAttribute("booking-data", JSON.stringify($rootScope.booking));
      $scope.seatMapWidget.setAttribute("functionalities-data", JSON.stringify($scope.functionalities[$rootScope.activeFilekey]));
      $scope.seatMapWidget.setAttribute("on-change", "onChangeWidget");

      document.getElementById('mcw-seatmaps').append($scope.seatMapWidget);
    };

    $scope.initWrapper = function (seatMapSegments) {
      $scope.resetSeatMapSidenav();
      $scope.passengersWithoutInfant = $scope.getPassengersWithoutInfant();

      // fetch seat-map if functionality seat display is enabled or if some of the passengers have seats
      if (seatMapSegments && seatMapSegments['currentSegment'] && $scope.isSeatMapDisplayable()) {
        $scope.getSeatMapData(seatMapSegments['currentSegment']);
      } else {
        $scope.status = 'seatMapDisplayError';
        $scope.seatMapSegments['errorSegments'].push($scope.data.segmentExtendedNumber);
      }
    }

    $scope.isSeatMapDisplayable = function() {
      return ($rootScope.isFunctionalityEnabled('seatDisplayEnabled') || segmentHelper.getNumberOfAncillaryPerSegment($scope.data, 'seat') > 0);
    };

    $scope.isSeatInBasket = function(seat) {
      return $scope.passengerSeatList.some(function(passenger) {
        return $filter('ltrim')(passenger.seat, '0') === $filter('ltrim')(seat, '0');
      });
    };

    $scope.getReservedSeatStatus = function(seat) {
      var reservedSeatStatus = null;

      var isSeatReserved = $scope.passengerSeatList.some(function(passenger) {
        reservedSeatStatus = $filter('ltrim')(passenger.seat, '0') === $filter('ltrim')(seat, '0') && passenger.status;

        return reservedSeatStatus;
      });

      if (isSeatReserved) {
        return reservedSeatStatus;
      }
    };

    $scope.getSeatMapData = function(data) {
      $scope.getPassengersList();
      $scope.updatePassengerSeatListWithBasket();

      var seatMapRequestParams = $scope.getSeatMapRequestParams(data);
      var logRequestParamsSeatmapOpened = $scope.getLogRequestParamsSeatmap(seatMapRequestParams);

      logsProvider.logInteraction(logRequestParamsSeatmapOpened);

      seatMap.requestSeatMap(seatMapRequestParams).then(
        function successCallback(response) {
          if (response.meta && response.meta.success && response.data) {
            $scope.status = 'seatMapDisplaySuccess';
            $scope.seatDeckList = response.data;

            $scope.setSeatDeck();
            $scope.toggleDeck($scope.seatDeckList[0]);

              var logRequestParamsSeatmapOpenedAvailable = angular.copy(logRequestParamsSeatmapOpened);
            logRequestParamsSeatmapOpenedAvailable.interactionName = 'seatMapOpenedAvailable';
            logsProvider.logInteraction(logRequestParamsSeatmapOpenedAvailable);

            $scope.allSeatsAlreadyReserved = $scope.passengersWithoutInfant.every(function(passenger) {
              return passenger.seatRequest && passenger.seatRequest && passenger.seatRequest.seat;
            });

            $scope.refreshBasketSeats();
            $scope.preSelectPassenger();
          } else {
            $scope.status = 'seatMapDisplayError';
            $scope.seatMapSegments['errorSegments'].push($scope.data.segmentExtendedNumber);
          }
        },
        function errorCallback() {
          $scope.status = 'seatMapDisplayError';
          $scope.seatMapSegments['errorSegments'].push($scope.data.segmentExtendedNumber);
        }
      );
    };

    $scope.getSeatMapRequestParams = function(data) {
      return {
        filekey: $scope.activeFilekey,
        lastname: $scope.activeLastname,
        bookingClass: {
          airlineCode: data.bookingClassAirlineCode,
          code: data.bookingClass
        },
        flightNumber: data.flightNumber,
        segmentNumber: data.segmentExtendedNumber,
        departureDate: moment.utc(data.departureDateTime).format('YYYY-MM-DD'),
        departureTime: moment.utc(data.departureDateTime).format('HH:mm:ss'),
        arrivalDate: moment.utc(data.arrivalDateTime).format('YYYY-MM-DD'),
        arrivalTime: moment.utc(data.arrivalDateTime).format('HH:mm:ss'),
        departure: data.departureAirportCode,
        destination: data.arrivalAirportCode,
        marketingAirlineCode: data.marketingAirlineCode,
        locale: $rootScope.activeLocale
      }
    };

    $scope.setSeatDeck = function() {
      angular.forEach($scope.seatDeckList, function() {
        if ($scope.seatDeckList[$scope.deckSelectedId]['attributes']['rangeOfRowsMin'] === $scope.seatDeckList[$scope.deckSelectedId]['attributes']['rangeOfRowsMax']) {
          $scope.deckSelectedId++;
        }
      });
    };

    $scope.getLogRequestParamsSeatmap = function(seatMapRequestParams) {
      return {
        interactionName: 'seatMapOpened',
        filekey: $rootScope.activeFilekey,
        lastname: $rootScope.activeLastname,
        language: $rootScope.activeLocale,
        details: seatMapRequestParams
      };
    };

    $scope.getPassengersList = function() {
      angular.forEach($scope.data.passengers, function(passenger, passengerIndex) {
        if (passenger.passengerTypeCode !== 'INF') {
          var seatRequest = passenger.seatRequest && passenger.seatRequest;
          var status = null;
          var seat = null;

          if (seatRequest && seatRequest.seat !== null) {
            seat = seatRequest.seat;
            status = seatRequest.status;
          }

          $scope.passengerSeatList.push({
            passengerIndex: passengerIndex,
            pnrPosition: passenger.pnrPosition,
            seat: seat,
            selected: false,
            chargeable: false,
            hasInfants: passenger.infants && passenger.infants.length > 0,
            status: status,
            priceList: null,
            seatConfirmed: false,
            passengerTypeCode: passenger.passengerTypeCode,
            selectedSeatCharacteristic: null
          });
        } else {
          $scope.pnrHasInfants = true;
        }
      });
    };

    $scope.preSelectPassenger = function() {
      // prioritizedPassenger does not have seat in basket or has unconfirmed seats
      var prioritizedPassenger = $scope.passengerSeatList && $scope.passengerSeatList.find(function(passengerSeat) {
        return passengerSeat.seat === null;
      }) || $scope.passengerSeatList.find(function(passengerSeat) {
        return passengerSeat.status === 'seatSelected';
      });

      var passengerPnrPositionToBeSelected = prioritizedPassenger ? prioritizedPassenger.pnrPosition : $scope.data.passengers[0].pnrPosition;

      $scope.selectPassenger(passengerPnrPositionToBeSelected);
    };

    $scope.updatePassengerSeatListWithBasket = function() {
      if ($scope.basket && !$filter('isEmpty')($scope.basket)) {
        var basketSeats = $scope.data && $scope.basket.seats && $scope.basket.seats[$scope.data.segmentExtendedNumber] ? $scope.basket.seats[$scope.data.segmentExtendedNumber].passengers : [];

        if (!$filter('isEmpty')(basketSeats)) {
          angular.forEach(basketSeats, function(basketSeat, pnrPosition) {
            angular.forEach($scope.passengerSeatList, function(passenger, index) {
              if (pnrPosition === passenger.pnrPosition && basketSeat.seatNumber) {
                $scope.passengerSeatList[index].seat = basketSeat.seatNumber;
                $scope.passengerSeatList[index].chargeable = basketSeat.chargeable;
                $scope.passengerSeatList[index].price = basketSeat.price;
                $scope.passengerSeatList[index].purchaseFee = basketSeat.purchaseFee;
                $scope.passengerSeatList[index].seatConfirmed = true;
                $scope.passengerSeatList[index].dateTimeAddedToBasket = basketSeat.dateTimeAddedToBasket;
              }
            });
          });
        }
      }
    };

    $scope.isAirlineCheckinOpen = function(data) {
      return segmentHelper.isAirlineCheckinOpen(data);
    };

    $scope.getPassengersWithoutInfant = function() {
      var adultOrChildPassengers = [];

      if ($scope.data && $scope.data.passengers) {
        angular.forEach($scope.data.passengers, function(passenger) {
          if (passenger.passengerTypeCode !== 'INF') {
            adultOrChildPassengers.push(passenger);
          }
        });
      }

      return adultOrChildPassengers;
    };

    $scope.aisleSeatPosition = function(columnList, column, index) {
      var currentColumnLocation = null;

      if (columnList && column) {
        angular.forEach(columnList, function(value) {
          if (value.column === column) {
            // To get the right index whenever the premium section has less column than economy. Please test with booking LKLFV6/HAIDER (for this case, G column becomes F within premium area, because no E column).
            index = columnList.findIndex(function(x) { return x.column == column });
            currentColumnLocation = value.location.toUpperCase();
            return false;
          }
        });

        if (currentColumnLocation === 'AISLE' || currentColumnLocation === 'WINDOW_AISLE') {
          if ($scope.hasSeatMapWindowAisle === null) {
            $scope.hasSeatMapWindowAisle = currentColumnLocation === 'WINDOW_AISLE';
          }

          var nextColumnLocation = null;

          if (currentColumnLocation === 'WINDOW_AISLE') {
            if (columnList[index + 1]) {
              nextColumnLocation = columnList[index + 1].location.toUpperCase();

              if (nextColumnLocation === 'AISLE' && !$scope.isLastColumnAisle) {
                $scope.isLastColumnAisle = true;
                return true;
              }
            }
          } else {
            if (columnList[index + 1] && columnList[index - 1]) {
              nextColumnLocation = columnList[index + 1].location.toUpperCase();

              if (nextColumnLocation === 'AISLE' && columnList[index + 2] && columnList[index + 2].location.toUpperCase() === 'WINDOW_AISLE') {
                return false;
              }

              if (!$scope.hasSeatMapWindowAisle && nextColumnLocation === 'WINDOW_AISLE') {
                $scope.isLastColumnAisle = true;
                return true;
              }

              if (((!$scope.hasSeatMapWindowAisle && nextColumnLocation === 'AISLE')
                && (columnList[index + 2].location.toUpperCase() !== 'AISLE' || columnList[index - 1].location.toUpperCase() !== 'AISLE')
                || ($scope.hasSeatMapWindowAisle && nextColumnLocation === 'WINDOW_AISLE'))
                && !$scope.isLastColumnAisle) {
                $scope.isLastColumnAisle = true;
                return true;
              }
            }
          }
        }
      }

      $scope.isLastColumnAisle = false;
    };

    $scope.selectPassenger = function(pnrPosition) {
      domHelper.modifyElementClassById('seat-' + $scope.selectedSeat, 'seat-clicked', 'remove');

      if ($scope.selectedPassengerPnrPosition !== pnrPosition) {
        $scope.isPassengerSeatPreselected = false;
      }

      angular.forEach($scope.passengerSeatList, function(passengerSeat, index) {
        if (passengerSeat.pnrPosition === pnrPosition) {
          $scope.selectedPassengerSeatInformation = passengerSeat;

          if (pnrPosition) {
            $scope.selectedPassengerPnrPosition = pnrPosition;
            $scope.selectedPassenger = $filter('filter')($scope.data.passengers, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];
            $scope.selectedPassenger = angular.merge($scope.selectedPassenger, $filter('filter')($scope.passengerSeatList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0]);

            $scope.openSeatMap();
            $scope.preparePriceSlider($scope.seatDeckList, pnrPosition, $scope.selectedPassenger);

            // Re-renders slider with a delay, so that it does not get loaded with both min/max at the start of the slider
            $timeout(function() {
              $scope.$broadcast('rzSliderForceRender');
            }, 500);
          }

          if (!passengerSeat.seat) {
            $scope.seatClicked = false;
            $scope.buttonState = 'add';
          }

          if (!$scope.isPassengerSeatPreselected) {
            $scope.preSelectPassengerReservedSeat(index);
            $scope.isPassengerSeatPreselected = true;
          }
        }

        $scope.passengerSeatList[index].selected = passengerSeat.pnrPosition === pnrPosition;
      });
    };

    $scope.areSelectedSeatsInBasket = function() {
      if ($scope.basket && $scope.basket.seats) {
        return $filter('toArray')($scope.basket.seats, true).some(function(segmentSeat) {
          return segmentSeat.passengers;
        });
      }
    };

    $scope.preSelectPassengerReservedSeat = function(index) {
      $scope.seatDeckList.map(function(seatDeck) {
        seatDeck.attributes.seatMapList.map(function(seatMap) {
          seatMap.rowList.map(function(row) {
            row.seatList.map(function(seat) {
              var seatPassenger = seat[$scope.passengerSeatList[index].pnrPosition];

              if (seatPassenger && seatPassenger.row + seatPassenger.column === $scope.passengerSeatList[index].seat) {
                $scope.selectSeat(seatPassenger, row, null, 'preSelect');
              }
            })
          })
        })
      });
    };

    $scope.isPassengerAllowedToReserve = function() {
      return $scope.selectedPassenger && !$scope.selectedPassenger.seatRequest && !$scope.isSeatSelectedByOtherPassenger;
    };

    $scope.isSeatSelectable = function(seat, row) {
      if ($scope.data && typeof seat == 'object') {
        return $scope.selectedPassenger && !$scope.isAirlineCheckinOpen($scope.data) &&
          !$scope.isSeatRestricted(seat, $scope.selectedPassengerSeatInformation, $scope.pnrHasInfants, row) && !$scope.isSeatCrew(seat) &&
          ($scope.isSegmentSeatReservationFunctionalityEnabled('seatReservationNonChargeableEnabled', seat) ||
            $scope.isSegmentSeatReservationFunctionalityEnabled('seatReservationChargeableEnabled', seat)) &&
          $scope.isSeatExisting(seat) && ($scope.isSeatAvailableForFree(seat) || ($scope.isSeatAvailableForCharge(seat) &&
            $scope.chargeableSeatWithPrice(seat, $scope.selectedPassengerPnrPosition)));
      }
    };

    $scope.isSeatRestricted = function(seat, passengerInformation, pnrHasInfants, row) {
      return $scope.isSeatAvailable(seat) &&
        $scope.hasSeatCharacteristic(seat, ['RESTRICTED_SEAT', 'PROTECTED_SEAT']) ||
        $scope.isSeatLastOffered(seat) ||
        $scope.isSeatNotAvailableForPassengerType(seat, passengerInformation) ||
        ($scope.isSeatAvailableForCharge(seat) ? !$scope.chargeableSeatWithPrice(seat, passengerInformation.pnrPosition) : false) ||
        ($scope.isExitRow(row) ? pnrHasInfants : false);
    };

    $scope.isSeatReservable = function(seat, row) {
      return seat && $scope.isPassengerAllowedToReserve() && $scope.isSeatSelectable(seat, row);
    };

    $scope.isSegmentSeatReservationFunctionalityEnabled = function(functionality, seat) {
      return $scope.functionalities[$rootScope.activeFilekey] && $scope.functionalities[$rootScope.activeFilekey][functionality] &&
        $scope.functionalities[$rootScope.activeFilekey][functionality][$scope.data.segmentExtendedNumber] &&
        (functionality === 'seatReservationNonChargeableEnabled' && !seat.originalChargeable || functionality === 'seatReservationChargeableEnabled' && seat.originalChargeable);
    };

    $scope.isAnySeatSelectable = function() {
      return $scope.seatDeckList.some(function(seatDeck) {
        return seatDeck.attributes.seatMapList.some(function(seatMap) {
          return seatMap.rowList.some(function(row) {
            return row.seatList.some(function(seat) {
              return $filter('toArray')(seat).some(function(seatPassenger) {
                return $scope.isSeatSelectable(seatPassenger, row);
              })
            })
          })
        })
      })
    };

    $scope.openBasket = function() {
      $mdSidenav('seatMapMain').close();
      basketHelper.toggleBasket(true);
    };

    $scope.getNumberOfSeatsInBasketPerSegment = function(segmentNumber) {
      if ($rootScope.basket.seats && segmentNumber) {
        return $filter('count')($rootScope.basket.seats[segmentNumber].passengers);
      }
    };

    $scope.isSeatSelected = function() {
      if (!$filter('isEmpty')($scope.passengerSeatList)) {
        return $scope.passengerSeatList.some(function(passengerSeat) {
          return passengerSeat.seat !== null && passengerSeat.chargeable && passengerSeat.status === 'seatSelected';
        });
      }
    };

    $scope.openSeatMap = function() {
      $scope.hasSeatMapWindowAisle = null;
      $mdSidenav('seatMapMain').open();
    };

    $scope.selectSeat = function(seat, row, event, interaction) {
      $scope.facilityCharacteristic = {};
      // disable click for non existing seats
      if (!$scope.isSeatExisting(seat)) return;

      domHelper.modifyElementClassById('seat-' + $scope.selectedSeat, 'seat-clicked', 'remove');

      var selectedPassenger = $filter('filter')($scope.passengerSeatList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0];

      if ($scope.selectedSeat === seat.row + seat.column) {
        $scope.seatClicked = !$scope.seatClicked;
        domHelper.modifyElementClassById('seat-' + seat.row + seat.column, 'seat-clicked', 'toggle', $scope.seatClicked);
      } else {
        $scope.seatClicked = true;

        if (!(selectedPassenger.status === 'seatSelected' && selectedPassenger.seat === seat.row + seat.column)) {
          domHelper.modifyElementClassById('seat-' + seat.row + seat.column, 'seat-clicked', 'add');
        }
      }

      $scope.selectedSeat = seat.row + seat.column;
      $scope.seatCharacteristicList = seat.seatCharacteristicList;
      $scope.seatToBeConfirmed = seat;
      $scope.rowToBeConfirmed = row;

      if ($scope.selectedPassengerPnrPosition) {
        $scope.selectedSeatPrice = null;

        if ($scope.isSeatReserved(seat, $scope.passengerSeatList) || $scope.isSeatSelectable(seat, row)) {
          $scope.selectedSeatPrice = seat.priceList ? seat.priceList.price : null;
        }
      }

      var isSeatAlreadyReserved = $scope.passengerSeatList.some(function(passengerSeat) {
        return passengerSeat.seat === $scope.selectedSeat && !passengerSeat.selected;
      });

      // Note: when refreshing the seat from basket, isSeatAlreadyReserved is true (as added in passengerSeatList from basket)
      // Prevented by setting the passengerSeat to selected in updateBasketSeat()
      if (isSeatAlreadyReserved || $scope.selectedPassenger.status === 'confirmed' || basketHelper.getNumberOfExtrasInBasketPerSegment($scope.data.segmentExtendedNumber, 'cashUpgrade') > 0) {
        return;
      }

      $scope.isSeatSelectedByOtherPassenger = $scope.passengerSeatList.some(function(passengerSeat) {
        return (passengerSeat.seat === (seat.row + seat.column)) && !passengerSeat.selected;
      });

      angular.forEach($scope.passengerSeatList, function(passengerSeat, index) {
        if (seat.existing && seat.available &&
          !$scope.isSeatRestricted(seat, $scope.selectedPassengerSeatInformation, $scope.pnrHasInfants, row)) {
          // Seat available and not taken by someone else
          if (passengerSeat.selected) {
            // If seat is timed out, buttonState is change (to refresh the passengerSeat data in the basket)
            if ($scope.passengerSeatList[index].seat === seat.row + seat.column && $scope.isPassengerSeatPreselected) {
              $scope.buttonState = 'remove';
            } else if (!$scope.passengerSeatList[index].seat) {
              $scope.buttonState = 'add';
            } else {
              $scope.buttonState = 'change';
            }

            $scope.passengerSeatList[index].seatConfirmed = false;
          }
        }
        // When the seats are refreshed on seatmap reopening, removes the unavailable seats. Here the array to show the messages are filled
        if ($scope.passengerSeatList[index].seat === seat.row + seat.column && !$scope.isPassengerSeatPreselected && $scope.isPassengerBasketSeatUnavailable($scope.passengerSeatList[index])) {
          $scope.buttonState = 'remove';
          $scope.removedBasketSeats.push($scope.passengerSeatList[index].seat);
        } else if (!$scope.isPassengerSeatPreselected && interaction === 'preSelect') {
          $scope.updatedBasketSeats.push($scope.passengerSeatList[index].seat);
        }
      });

      $scope.modifySeatSelection(event);
    };

    $scope.setFacilityCharacteristic = function(facilityCharacteristic) {
      $scope.facilityCharacteristic = {
        text: gettextCatalog.getString(facilityCharacteristic),
        logo: facilityCharacteristic
      };

      $scope.selectedSeat = null;
      $scope.selectedSeatPrice = null;
      $scope.seatCharacteristicList = [];
    };

    $scope.selectFacility = function(seat, row, columnList, index) {
      $scope.seatClicked = true;
      var facilityCharacteristic = null;

      if ($scope.isSeatGalley(seat) || $scope.isSeatAdjacentToGalley(seat) || $scope.rowHasGalley(seat, row, columnList, index)) {
        facilityCharacteristic = 'Galley';
      } else if ($scope.isSeatLavatory(seat) || $scope.isSeatAdjacentToLavatory(seat) || $scope.rowHasLavatory(seat, row, columnList, index)) {
        facilityCharacteristic = 'Toilet';
      } else if ($scope.isSeatStorage(seat)) {
        facilityCharacteristic = 'Storage';
      } else if ($scope.isSeatStairs(seat)) {
        facilityCharacteristic = 'Stairs';
      } else if ($scope.isSeatBassinet(seat, row)) {
        facilityCharacteristic = 'Bassinet';
      } else if ($scope.isSeatWardrobe(seat)) {
        facilityCharacteristic = 'Wardrobe';
      }

      $scope.setFacilityCharacteristic(facilityCharacteristic);
    };

    $scope.selectRowFacility = function(seat, columnList, rowCharacteristic, index) {
      $scope.seatClicked = true;
      var facilityCharacteristic = null;

      if ($scope.seatRowCharacteristicGalley(seat, columnList, rowCharacteristic, index)) {
        facilityCharacteristic = 'Galley';
      } else if ($scope.seatRowCharacteristicLavatory(seat, columnList, rowCharacteristic, index)) {
        facilityCharacteristic = 'Toilet';
      } else if ($scope.seatRowCharacteristicStorage(seat, columnList, rowCharacteristic, index)) {
        facilityCharacteristic = 'Storage';
      } else if ($scope.seatRowCharacteristicStairs(seat, columnList, rowCharacteristic, index)) {
        facilityCharacteristic = 'Stairs';
      } else if ($scope.seatRowCharacteristicWardrobe(seat, columnList, rowCharacteristic, index)) {
        facilityCharacteristic = 'Wardrobe';
      }

      $scope.setFacilityCharacteristic(facilityCharacteristic);
    };

    $scope.modifySeatSelection = function(event) {
      if (!profileHelper.isLoggedIn() && $rootScope.isAuthenticated) {
        var dialogData = {
          onShowing: 'loginFromSeatMap'
        };

        componentHelper.openDialog('/element/dialog/profile/main', event, dialogData);
        profile.logout();

        return false;
      }

      if ((!$scope.isSeatReservable($scope.seatToBeConfirmed, $scope.rowToBeConfirmed) || !profileHelper.isLoggedIn()) && $scope.isPassengerSeatPreselected) {
        return false;
      }

      $scope.seatConfirmed = true;

      switch ($scope.buttonState) {
        case 'add':
        case 'change':
          if ($scope.seatToBeConfirmed) {
            angular.forEach($scope.passengerSeatList, function(passengerSeat, index) {
              if (passengerSeat.selected) {
                if ($scope.passengerSeatList[index].seat) {
                  domHelper.modifyElementClassById('seat-' + $scope.passengerSeatList[index].seat, 'seat-clicked', 'remove');
                }

                $scope.passengerSeatList[index].seat = $scope.seatToBeConfirmed.row + $scope.seatToBeConfirmed.column;
                $scope.passengerSeatList[index].status = 'seatSelected';
                $scope.passengerSeatList[index].seatConfirmed = true;
                $scope.passengerSeatList[index].chargeable = $scope.seatToBeConfirmed.chargeable;
                $scope.passengerSeatList[index].originalChargeable = $scope.seatToBeConfirmed.originalChargeable;
                $scope.passengerSeatList[index].position = $scope.seatToBeConfirmed.position;
                $scope.passengerSeatList[index].ssrCode = $scope.seatToBeConfirmed.ssrCode;
                $scope.passengerSeatList[index].description = $scope.seatToBeConfirmed.description;
                $scope.passengerSeatList[index].diagnosticsId = $scope.seatToBeConfirmed.diagnosticsId;
                $scope.passengerSeatList[index].validatingCarrier = $scope.seatToBeConfirmed.validatingCarrier;
                $scope.passengerSeatList[index].seatService = $scope.seatToBeConfirmed.seatService;
                $scope.passengerSeatList[index].itemMarkups = !$filter('isEmpty')($scope.seatToBeConfirmed.priceList) ? $scope.seatToBeConfirmed.priceList.itemMarkups: null;
                $scope.passengerSeatList[index].price = !$filter('isEmpty')($scope.seatToBeConfirmed.priceList) ? $scope.seatToBeConfirmed.priceList.price: null;
                $scope.passengerSeatList[index].purchaseFee = !$filter('isEmpty')($scope.seatToBeConfirmed.priceList) ? $scope.seatToBeConfirmed.priceList.purchaseFee: null;
                $scope.passengerSeatList[index].selectedSeatCharacteristic = $scope.seatToBeConfirmed.seatCharacteristicList;
                $scope.passengerSeatList[index].underReserve = $scope.seatToBeConfirmed.underReserve;

                var newId = '#seat-' + $scope.seatToBeConfirmed.row + $scope.seatToBeConfirmed.column;

                domHelper.modifyElementClassById(newId, 'seat-clicked', 'remove');
                domHelper.modifyElementClassById(newId, 'seat-selected', 'add');

                $scope.reserveSeat();

                $scope.buttonState = 'remove';
              }
            });
          }
          break;
        case 'remove':
          angular.forEach($scope.passengerSeatList, function(passengerSeat, index) {
            if (passengerSeat.selected && !$scope.isSeatSelectedByOtherPassenger) {
              if (passengerSeat.seat === ($scope.selectedSeat)) {
                $scope.seatClicked = false;
                var id = '#seat-' + $scope.passengerSeatList[index].seat;
                domHelper.modifyElementClassById(id, 'seat-selected', 'remove');
                $scope.passengerSeatList[index].seat = null;
                $scope.passengerSeatList[index].status = null;
                $scope.passengerSeatList[index].price = null;
                $scope.passengerSeatList[index].purchaseFee = null;
                $scope.passengerSeatList[index].seatConfirmed = false;

                basketHelper.removeSeatFromBasket($scope.data.segmentExtendedNumber, passengerSeat.pnrPosition, event);
                $scope.selectedPassenger = angular.merge($scope.selectedPassenger, $filter('filter')($scope.passengerSeatList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0]);

                return false;
              }
            }
          });

          break;
      }
    };

    $scope.isAnySelectedSeatUnderReserve = function() {
      if ($scope.basket && $scope.basket.seats && $scope.basket.seats[$scope.data.segmentExtendedNumber]) {
          return $filter('toArray')($rootScope.basket.seats[$scope.data.segmentExtendedNumber].passengers, true).some(function(passengerSeat) {
            return passengerSeat.underReserve;
          });
        }
    };

    $scope.reserveSeat = function() {
      $localStorage.cockpit.baskets[$rootScope.activeFilekey] = $localStorage.cockpit.baskets[$rootScope.activeFilekey] || {};
      $scope.basket = $localStorage.cockpit.baskets[$rootScope.activeFilekey];

      var basketSeats = $scope.basket.seats || {};

      basketSeats[$scope.data.segmentExtendedNumber] = basketSeats[$scope.data.segmentExtendedNumber] || {};
      basketSeats[$scope.data.segmentExtendedNumber]['pnrSource'] = $scope.seatDeckList[$scope.deckSelectedId].attributes.pnrSource;
      basketSeats[$scope.data.segmentExtendedNumber]['departureAirportCode'] = $scope.data.departureAirportCode;
      basketSeats[$scope.data.segmentExtendedNumber]['departureAirportName'] = $scope.data.departureAirportName;
      basketSeats[$scope.data.segmentExtendedNumber]['arrivalAirportCode'] = $scope.data.arrivalAirportCode;
      basketSeats[$scope.data.segmentExtendedNumber]['arrivalAirportName'] = $scope.data.arrivalAirportName;
      basketSeats[$scope.data.segmentExtendedNumber]['flown'] = $scope.data.flown;
      basketSeats[$scope.data.segmentExtendedNumber]['departureDateTime'] = $scope.data.departureDateTime;
      basketSeats[$scope.data.segmentExtendedNumber]['marketingAirlineCode'] = $scope.data.marketingAirlineCode;
      basketSeats[$scope.data.segmentExtendedNumber]['marketingAirlineName'] = $scope.data.marketingAirlineName;
      basketSeats[$scope.data.segmentExtendedNumber]['marketingAirlineIcao'] = $scope.data.marketingAirlineIcao;
      basketSeats[$scope.data.segmentExtendedNumber]['crypticSegmentValue'] = $scope.data.crypticSegmentValue;
      basketSeats[$scope.data.segmentExtendedNumber]['passengers'] = basketSeats[$scope.data.segmentExtendedNumber]['passengers'] || {};

      $scope.selectedPassenger = angular.merge($scope.selectedPassenger, $filter('filter')($scope.passengerSeatList, {'pnrPosition': $scope.selectedPassengerPnrPosition})[0]);

      var passengerSeatData = {
        seatNumber: $scope.selectedPassenger.seat,
        position: $scope.selectedPassenger.position,
        ssrCode: $scope.selectedPassenger.ssrCode,
        description: $scope.selectedPassenger.description,
        diagnosticsId: $scope.selectedPassenger.diagnosticsId,
        validatingCarrier: $scope.selectedPassenger.validatingCarrier,
        price: $scope.selectedPassenger.price,
        purchaseFee: $scope.selectedPassenger.purchaseFee,
        itemMarkups: $scope.selectedPassenger.itemMarkups,
        chargeable: $scope.selectedPassenger.chargeable,
        originalChargeable: $scope.selectedPassenger.originalChargeable,
        airlineCode: $scope.data.marketingAirlineCode,
        passengerTypeCode: $scope.selectedPassenger.passengerTypeCode,
        row: $scope.seatToBeConfirmed.row,
        column: $scope.seatToBeConfirmed.column,
        seatCharacteristic: $scope.selectedPassenger.selectedSeatCharacteristic,
        seatService: $scope.selectedPassenger.seatService,
        dateTimeAddedToBasket: moment().format(),
        underReserve: $scope.selectedPassenger.underReserve,
        paxId: $scope.seatToBeConfirmed.paxId,
        segmentId: $scope.seatToBeConfirmed.segmentId
      };

      if (basketSeats) {
        basketSeats[$scope.data.segmentExtendedNumber]['passengers'][$scope.selectedPassenger.pnrPosition] = passengerSeatData;
      }

      $scope.selectedPassenger.status = 'seatInBasket';

      $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats = $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats || {};
      $localStorage.cockpit.baskets[$rootScope.activeFilekey].seats = basketSeats;
      $rootScope.$emit('refreshBasket');
    };

    $scope.toggleDeck = function(deck) {
      $scope.deckSelectedId = deck.id;
      $scope.facilityCharacteristic = {};
      $scope.seatClicked = false;

      var numberOfColumns = deck.attributes && deck.attributes.numberOfColumns;

      switch (true) {
        case (numberOfColumns < 5):
          $scope.seatMapScaling = 1.5;
          break;
        case (numberOfColumns < 7):
          $scope.seatMapScaling = 1.25;
          break;
        default:
          $scope.seatMapScaling = 1;
          break;
      }
    };

    $scope.toggleMenuRight = function() {
      $scope.close();
      basketHelper.toggleBasket();
    };

    $scope.redirectToCheckout = function(event) {
      $scope.close();
      basketHelper.redirectToCheckout(event);
    };

    $scope.openNextSeatMap = function(segment) {
      $scope.status = 'loading';
      var segmentIndex = $rootScope.booking.segments.indexOf(segment);
      var nextIndex = (segmentIndex + 1) % $rootScope.booking.segments.length;
      $scope.resetSelectedPassenger = true;

      if (seatMapHelper.isSeatMapAvailable($rootScope.booking.segments[nextIndex], 'seatDisplayEnabled', 'seat') && !segmentHelper.isActionDisabled('seatDisplayEnabled', 'seat', $rootScope.booking.segments[nextIndex])) {
        if ($scope.seatMapSegments && $scope.seatMapSegments['remainingSegments'].includes($rootScope.booking.segments[nextIndex])) {
          $scope.seatMapSegments['remainingSegments'] = $scope.seatMapSegments['remainingSegments'].filter(function(remainingSeatMapSegment) {
            return remainingSeatMapSegment.id !== $rootScope.booking.segments[nextIndex].id;
          });
          $scope.seatMapSegments['currentSegment'] = $rootScope.booking.segments[nextIndex];

          seatMapHelper.openSeatMap($scope.seatMapSegments['currentSegment'], $scope.seatMapSegments);
        }
      } else {
        $scope.openNextSeatMap($rootScope.booking.segments[nextIndex]);
      }
    };

    $scope.openFirstLoopingSeatMap = function() {
      if ($filter('count')($scope.seatMapSegments['enabledSegments']) > 0) {
        seatMapHelper.openSeatMapContainer($scope.seatMapSegments['enabledSegments'][0].segmentExtendedNumber);
      }
    };

    $scope.setButtonAction = function() {
      if ($scope.seatMapSegments && 'enabledSegments' in $scope.seatMapSegments) {
        var buttonAction = 'next-flight';

        if ($filter('count')($scope.seatMapSegments['remainingSegments']) === 0) {
          if ($scope.areSelectedSeatsInBasket()) {
            buttonAction = 'checkout';
          } else if ($filter('count')($scope.seatMapSegments['enabledSegments']) === 1 ||
              ($filter('count')($scope.seatMapSegments['enabledSegments']) === $filter('count')($scope.seatMapSegments['errorSegments']))) {
            buttonAction = 'close';
          } else {
            buttonAction = 'first-flight';
          }
        }

        return buttonAction;
      }
    };

    $scope.isSeatAlreadyReserved = function() {
      return $scope.passengerSeatList.some(function(passengerSeat) {
        return (passengerSeat.seat === $scope.selectedSeat && passengerSeat.status === 'confirmed');
      });
    };

    $scope.hasSeatCharacteristic = function(seat, characteristicArray) {
      if (seat && !$filter('isEmpty')(seat.seatCharacteristicList)) {
        return seat.seatCharacteristicList.some(function(seatCharacteristic) {
          return characteristicArray.includes(seatCharacteristic);
        });
      }
    };

    $scope.hasRowCharacteristic = function(row, characteristicArray) {
      if (row && !$filter('isEmpty')(row.rowCharacteristicList) && row.rowCharacteristicList instanceof Array) {
        return row.rowCharacteristicList.some(function(rowCharacteristic) {
          return characteristicArray.includes(rowCharacteristic.characteristic);
        });
      }
    };

    $scope.isExitRow = function(row, previousRow) {
      return $scope.hasRowCharacteristic(row, ['EXIT_ROW']) && !$scope.isPreviousRowExit(previousRow);
    };

    $scope.isPreviousRowExit = function(previousRow) {
      if (typeof previousRow === 'undefined') {
        return false;
      }

      return $scope.hasRowCharacteristic(previousRow, ['EXIT_ROW']);
    };

    $scope.isRowOverwing = function(row) {
      return $scope.hasRowCharacteristic(row, ['OVERWING_ROW']);
    };

    $scope.isRowOverwingFirst = function(row, rowList, seatMapList, rowIndex, seatMapIndex) {
      var index = rowIndex-1;

      if (row && rowList) {
        if (!rowList[rowIndex-1]) {
          if (seatMapList[seatMapIndex -1]) {
            rowList = seatMapList[seatMapIndex-1].rowList;
            index = seatMapList[seatMapIndex-1].rowList.length - 1;
          } else {
            return true;
          }
        }

        return $scope.isRowOverwing(row) && !$scope.isRowOverwing(rowList[index]);
      }
    };

    $scope.isRowOverwingLast = function(row, rowList, seatMapList, rowIndex, seatMapIndex) {
      var index = rowIndex + 1;

      if (row && rowList) {
        if (!rowList[rowIndex + 1]) {
          if (seatMapList[seatMapIndex + 1]) {
            rowList = seatMapList[seatMapIndex + 1].rowList;
            index = 0;
          } else {
            return true;
          }
        }

        return $scope.isRowOverwing(row) && !$scope.isRowOverwing(rowList[index]);
      }
    };

    $scope.rowHasGalley = function(seat, row, column, index) {
      return $scope.rowCabinHasCharacteristics(seat, row, column, index, ['GALLEY', 'BAR']);
    };

    $scope.rowHasLavatory = function(seat, row, column, index) {
      return $scope.rowCabinHasCharacteristics(seat, row, column, index, ['LAVATORY']);
    };

    $scope.rowCabinHasCharacteristics = function(seat, row, column, index, characteristicArray) {
      return row && seat && row.rowCharacteristicList && row.rowCharacteristicList.length > 0 && row.rowCharacteristicList.some(function(rowCharacteristic) {
        if (rowCharacteristic.source === 'rowCabin') {
          return rowCharacteristic.characteristic.some(function(characteristics) {
            return characteristicArray.includes(characteristics.characteristic) && characteristics.position === column[index].position && seat.column === column[index].column;
          });
        }
      });
    };

    $scope.rowHasCharacteristics = function(row) {
      return row && row.rowCharacteristicList && row.rowCharacteristicList.length > 0 && row.rowCharacteristicList.some(function(rowCharacteristic) {
        return rowCharacteristic.source === 'rowCabin';
      });
    };

    $scope.sanitizeRowCharacteristic = function(rowCharacteristic) {
      return rowCharacteristic && (rowCharacteristic.source === 'rowCabin' && rowCharacteristic.characteristic.some(function(characteristic) {
        return characteristic.characteristic !== 'BULKHEAD' && characteristic.characteristic !== 'EXIT_DOOR';
      }));
    };

    $scope.seatsHasFacilities = function(seats) {
      return seats && seats.some(function(seat) {
        return seat && seat[$scope.selectedPassengerPnrPosition] && seat[$scope.selectedPassengerPnrPosition].seatCharacteristicList.indexOf('BASSINET_FACILITY_SEAT') !== -1;
      });
    };

    $scope.seatRowCharacteristicGalley = function(seat, column, rowCharacteristic, index) {
      return this.seatRowHasCharacteristic(seat, column, rowCharacteristic, index, ['GALLEY', 'BAR']);
    };

    $scope.seatRowCharacteristicLavatory = function(seat, column, rowCharacteristic, index) {
      return $scope.seatRowHasCharacteristic(seat, column, rowCharacteristic, index, ['LAVATORY']);
    };

    $scope.seatRowCharacteristicWardrobe = function(seat, column, rowCharacteristic, index) {
      return $scope.seatRowHasCharacteristic(seat, column, rowCharacteristic, index, ['CLOSET']);
    };

    $scope.seatRowCharacteristicStairs = function(seat, column, rowCharacteristic, index) {
      return $scope.seatRowHasCharacteristic(seat, column, rowCharacteristic, index, ['STAIRS_TO_UPPER_DECK']);
    };

    $scope.seatRowCharacteristicStorage = function(seat, column, rowCharacteristic, index) {
      return $scope.seatRowHasCharacteristic(seat, column, rowCharacteristic, index, ['STORAGE', 'STORAGE_SPACE']);
    };

    $scope.seatRowHasCharacteristic = function(seat, column, rowCharacteristic, index, characteristicArray) {
      if (seat) {
        if (rowCharacteristic && rowCharacteristic.characteristic instanceof Array) {
          return rowCharacteristic.characteristic.some(function(characteristics) {
            return characteristicArray.includes(characteristics.characteristic) && characteristics.position === column[index].position && seat.column === column[index].column;
          });
        } else {
          return characteristicArray.includes(rowCharacteristic.characteristic) && rowCharacteristic.position === column[index].position && seat.column === column[index].column;
        }
      }
    };

    $scope.isColumnAvailable = function(rows, seatIndex) {
      if (rows && rows.length !== 0) {
        return rows.some(function(row) {
          return $filter('toArray')(row.seatList[seatIndex]).some(function(seatPassenger) {
            return typeof seatPassenger == 'object' && seatPassenger.existing === true;
          })
        });
      }
    };

    $scope.isSeatExisting = function(seat) {
      var isSeat = true;

      if (seat && seat.seatCharacteristicList.length > 0) {
        isSeat = !seat.seatCharacteristicList.some(function(seatCharacteristic) {
          if (seatCharacteristic instanceof Array) {
            seatCharacteristic.some(function(seatCharacteristic) {
              return seatCharacteristic.startsWith('NO_SEAT') || seatCharacteristic === 'GENERAL_FACILITY'
            });
          } else {
            return seatCharacteristic.startsWith('NO_SEAT') || seatCharacteristic === 'GENERAL_FACILITY';
          }
        });
      }

      return seat && seat.existing && isSeat;
    };

    $scope.isSeatGalley = function(seat) {
      return seat && seat.available && $scope.hasSeatCharacteristic(seat, ['NO_SEAT_GALLEY']);
    };

    $scope.isSeatAdjacentToGalley = function(seat) {
      return seat && seat.available && $scope.hasSeatCharacteristic(seat, ['ADJACENT_TO_GALLEY_SEAT']);
    };

    $scope.isSeatAdjacentToLavatory = function(seat) {
      return seat && seat.available && $scope.hasSeatCharacteristic(seat, ['ADJACENT_TO_LAVATORY_SEAT']);
    };

    $scope.isSeatLavatory = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['NO_SEAT_LAVATORY']);
    };

    $scope.isSeatLastOffered = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['OFFERED_LAST_SEAT']);
    };

    $scope.isSeatBassinet = function(seat, row) {
      return seat && row && ($scope.hasSeatCharacteristic(seat, ['BASSINET_FACILITY_SEAT']));
    };

    $scope.isSeatStorage = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['STORAGE', 'STORAGE_SPACE', 'NO_SEAT_LUGGAGE_STORE']);
    };

    $scope.isSeatWardrobe = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['CLOSET']);
    };

    $scope.isSeatAvailable = function(seat) {
      return seat && seat.available;
    };

    $scope.isSeatAvailableForFree = function(seat) {
      return seat && seat.available && !seat.chargeable && !$scope.isSeatCrew(seat);
    };

    $scope.isSeatAvailableForCharge = function(seat) {
      return seat && seat.available && seat.chargeable;
    };

    $scope.chargeableSeatWithPrice = function(seat, pnrPosition) {
      return seat.chargeable && pnrPosition && !$filter('isEmpty')(seat.priceList);
    };

    $scope.isSeatTaken = function(seat) {
      return seat && !seat.available && !$scope.isSeatCrew(seat);
    };

    $scope.isSeatReserved = function(seat, passengerSeatList) {
      if (!$filter('isEmpty')(passengerSeatList)) {
        return passengerSeatList.some(function(passengerSeat) {
          return (passengerSeat.seat === (0 + (seat.row + seat.column)) || passengerSeat.seat === (seat.row + seat.column)) && passengerSeat.status === 'confirmed';
        });
      }

      return false;
    };

    $scope.isSeatCrew = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['SEAT_CREW']);
    };

    $scope.isSeatStairs = function(seat) {
      return seat && $scope.hasSeatCharacteristic(seat, ['NO_SEAT_STAIRS_TO_UPPER_DECK']);
    };

    $scope.isSeatNotAvailableForPassengerType = function(seat, passengerSelected) {
      return seat && passengerSelected && (
        (passengerSelected.hasInfants && $scope.hasSeatCharacteristic(seat, ['NOT_ALLOWED_FOR_INFANT_SEAT', 'EXIT_ROW_SEAT'])) ||
        (passengerSelected.passengerTypeCode === 'CHD' && $scope.hasSeatCharacteristic(seat, ['NO_CHILD_SEAT'])) ||
        (passengerSelected.passengerTypeCode === 'ADT' && $scope.hasSeatCharacteristic(seat, ['UNACCOMPANIED_MINORS_SEAT']))
      );
    };

    $scope.getSeatCharacteristics = function(seat, passengerInformation, pnrHasInfants, row, passengerSeatList) {
      if (seat) {
        if ($scope.isSeatTaken(seat) && $scope.isSeatExisting(seat)) {
          if ($scope.isSeatReserved(seat, passengerSeatList)) {
            return 'SEAT_RESERVED';
          }

          return 'SEAT_TAKEN';
        } else if ($scope.isSeatCrew(seat) && !$scope.isSeatTaken(seat) && $scope.isSeatExisting(seat)) {
          return 'SEAT_CREW';
        } else if ($scope.isSeatRestricted(seat, passengerInformation, pnrHasInfants, row) && $scope.isSeatExisting(seat) && !$scope.isSeatTaken(seat)) {
          return 'RESTRICTED_SEAT';
        } else {
          if ($scope.isSeatAvailableForFree(seat) && $scope.isSeatExisting(seat)) {
            return 'SEAT_FREE';
          } else if ($scope.isSeatAvailableForCharge(seat) && $scope.isSeatExisting(seat)) {
            return 'CHARGEABLE_SEAT';
          } else if ($scope.isSeatExisting(seat)) {
            return null;
          } else {
            if ($scope.hasSeatCharacteristic(seat, ['NO_SEAT'])) {
              return null;
            }

            return 'SEAT_CHARACTERISTIC';
          }
        }
      }
    };

    $scope.displayHorizontalScrollIndicator = function(elementId, direction) {
      return domHelper.displayHorizontalScrollIndicator(elementId, direction);
    };

    $scope.displayVerticalScrollIndicator = function(elementId, direction) {
      return domHelper.displayVerticalScrollIndicator(elementId, direction);
    };

    $scope.getPassengersIndexForReservedSeat = function(seat, passengerSeatList) {
      if (seat) {
        return passengerSeatList.findIndex(function(passenger) {
          return $filter('ltrim')(passenger.seat, '0') === ($filter('ltrim')(seat.row, '0') + seat.column);
        }) + 1;
      }
    };

    $scope.scrollSeatMapContainer = function(direction) {
      switch (direction) {
        case 'top':
          angular.element('#seat-map-container').animate({scrollTop: 0}, "slow");
          break;
        case 'bottom':
          angular.element('#seat-map-container').animate({scrollTop: document.getElementById('seat-map-container').scrollHeight}, "slow");
          break;
      }
    };

    $scope.scrollToPreselectedPassenger = function() {
      if (!$rootScope.isLargeScreen) {
        $timeout(function() {
          // 30 is the width of the left-shadow.
          var scrollingOffset = document.getElementById('passenger-' + $scope.selectedPassengerPnrPosition).offsetLeft - 30;
          $scope.selectedPassengerPnrPosition && angular.element('#passenger-list-container').animate({scrollLeft: scrollingOffset}, "slow");
        }, 500);
      }
    };

    $scope.isRowFirst = function(row, seatMap) {
      return row.number == seatMap.rangeOfRowsMin;
    };

    $scope.preparePriceSlider = function(seatDeckList, pnrPosition, selectedPassenger) {
      var sliderPassengerTypeLabel = selectedPassenger && selectedPassenger.passengerTypeCode ? selectedPassenger.passengerTypeCode : 'ADT';
      sliderPassengerTypeLabel = selectedPassenger && selectedPassenger.hasInfants ? sliderPassengerTypeLabel + '-INF' : sliderPassengerTypeLabel;

      // Sets minimum price to undefined to be overwritten correctly by the absolute minimum price of the seat map
      $scope.slider = {
        minValue: undefined,
        maxValue: 0,
        options: {
          floor: undefined,
          ceil: 0,
          translate: function(value) {
            return $filter('currencyFormatter')(value);
          }
        }
      };

      // Loops through all the seatDecks to get the min and max price. Sets slider's ceil and floor (slider labels)
      if (!$scope.slidersPerPassengerType[sliderPassengerTypeLabel]) {
        seatDeckList.map(function(seatDeck) {
          seatDeck.attributes.seatMapList.map(function(seatList) {
            seatList.rowList.map(function(row) {
              row.seatList.map(function(seat) {
                if (seat[pnrPosition] && seat[pnrPosition].priceList) {
                  if (seat[pnrPosition].priceList && (seat[pnrPosition].priceList.price || seat[pnrPosition].priceList.price == 0) && $scope.isSeatReservable(seat[pnrPosition], row)) {
                    if (seat[pnrPosition].priceList.price > $scope.slider.maxValue) {
                      $scope.slider.maxValue = Math.ceil(seat[pnrPosition].priceList.price);
                      $scope.slider.options.ceil = Math.ceil(seat[pnrPosition].priceList.price);
                    } else if (seat[pnrPosition].priceList.price < $scope.slider.minValue || $scope.slider.minValue === undefined) {
                      $scope.slider.minValue = Math.floor(seat[pnrPosition].priceList.price);
                      $scope.slider.options.floor = Math.floor(seat[pnrPosition].priceList.price);
                    }
                  }
                }
              })
            })
          })
        });

        $scope.slider.options.floor = $scope.slider.options.floor === undefined ? 0 : $scope.slider.options.floor;
        $scope.slider.minValue = $scope.slider.minValue === undefined ? 0 : $scope.slider.minValue;
        $scope.slidersPerPassengerType[sliderPassengerTypeLabel] = $scope.slider;
      } else {
        $scope.slider = $scope.slidersPerPassengerType[sliderPassengerTypeLabel];
      }

      $scope.isSliderShown = $scope.slider.maxValue - $scope.slider.minValue > 1;
    };

    $scope.refreshBasketSeats = function() {
      if ($rootScope.basket && $rootScope.basket.seats && $rootScope.basket.seats[$scope.data.segmentExtendedNumber] && $rootScope.basket.seats[$scope.data.segmentExtendedNumber].passengers) {
        $filter('toArray')($rootScope.basket.seats[$scope.data.segmentExtendedNumber].passengers, true).map(function(passengerSeat) {
          $scope.updateBasketSeat(passengerSeat.$key);
        });
      }

      $scope.updatePassengerSeatListWithBasket();
    };

    $scope.updateBasketSeat = function(pnrPosition) {
      var selectedPassengerSeat = $scope.passengerSeatList.find(function(passengerSeat) {
        return passengerSeat.pnrPosition === pnrPosition;
      });

      $scope.passengerSeatList[$scope.passengerSeatList.indexOf(selectedPassengerSeat)].selected = selectedPassengerSeat.pnrPosition === pnrPosition;
      $scope.selectPassenger(pnrPosition);
    };

    $scope.isPassengerBasketSeatUnavailable = function(passengerSeat) {
      return $scope.seatDeckList[$scope.deckSelectedId] &&
        $scope.seatDeckList[$scope.deckSelectedId].attributes &&
        $scope.seatDeckList[$scope.deckSelectedId].attributes.seatMapList.some(function(seatMap) {
          return seatMap.rowList.some(function(row) {
            return row.seatList.some(function(seat) {
              return passengerSeat.seat === seat[passengerSeat.pnrPosition].row + seat[passengerSeat.pnrPosition].column && !$scope.isSeatReservable(seat[passengerSeat.pnrPosition], row)
            });
          });
        });
    };

    $scope.openLoginDialog = function(event) {
      // Un-select seat that was selected when not logged in
      $scope.selectSeat($scope.seatToBeConfirmed, $scope.rowToBeConfirmed, event);

      componentHelper.openDialog('/element/dialog/profile/main', event);
    };

    $scope.isSeatFiltered = function(seatPrice) {
      if ($scope.slider && $scope.slider.maxValue && $scope.slider.minValue) {
        return $scope.isSliderShown && !((Math.floor(seatPrice) >= $scope.slider.minValue || Math.ceil(seatPrice) >= $scope.slider.minValue) && (Math.ceil(seatPrice) <= $scope.slider.maxValue || Math.floor(seatPrice) <= $scope.slider.maxValue));
      }
    }
  }
]);
