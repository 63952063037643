cockpitApp.provider('bookingProvider', [
  function () {
    this.$get = ['$q', '$localStorage', '$filter', '$rootScope', 'cockpitClient', 'gettextCatalog', 'componentHelper', 'logsProvider', '$mdDialog', 'navigationHelper', 'basketHelper', 'functionalities', 'bookingHelper', 'segmentHelper', 'profileHelper', 'profile',
      function ($q, $localStorage, $filter, $rootScope, cockpitClient, gettextCatalog, componentHelper, logsProvider, $mdDialog, navigationHelper, basketHelper, functionalities, bookingHelper, segmentHelper, profileHelper, profile) {

        return {
          updateBooking: updateBooking,
          removeBooking: removeBooking,
          cancelBooking: cancelBooking,
          isLocalStorageFull: isLocalStorageFull,
          clearBookingsFromWebStorage: clearBookingsFromWebStorage
        };

        function updateBooking(filekey, lastname, update, postAction, origin) {
          var refreshedBookingTimeStart = performance.now();
          var booking = bookingHelper.getBookings()[filekey];
          $rootScope.activePnrIdentifier = booking ? booking.pnrIdentifier : null;
          $rootScope.bookingsUpdating[filekey] = true;
          $rootScope.setoo[filekey] = null;

          var deferred = $q.defer();
          var requestData = {
            pnrIdentifier: $rootScope.activePnrIdentifier,
            filekey: filekey,
            lastname: lastname,
            origin: origin,
            locale: $rootScope.activeLocale,
            update: update || false
          };

          cockpitClient.getBooking(requestData).then(
            function successCallback(response) {
              var refreshedBookingTimeEnd = performance.now();
              var processingTimeInSeconds = (refreshedBookingTimeEnd - refreshedBookingTimeStart) / 1000;
              var success = response.meta && response.meta.success && response.data;

              if (success) {
                var booking = parseBooking(response.data);
                processBooking(booking);
                addBooking(booking, origin);
                basketHelper.validateBasket();
              }

              if (update) {
                var logRequestParams = {
                  interactionName: 'bookingRefreshed',
                  filekey: filekey,
                  lastname: lastname,
                  language: $rootScope.activeLocale,
                  details: {
                    origin: origin,
                    update: update,
                    processingTimeInSeconds: processingTimeInSeconds
                  }
                };

                logsProvider.logInteraction(logRequestParams);
              }

              booking = booking || bookingHelper.getBookings()[filekey];
              postUpdateBooking(response, requestData, postAction, true, origin, booking);
              deferred.resolve(response.meta);
            },
            function errorCallback(error) {
              $mdDialog.cancel();
              postUpdateBooking(error, requestData, postAction, false, origin, bookingHelper.getBookings()[filekey]);
              deferred.reject(error);
            }
          );

          return deferred.promise;
        }

        function postUpdateBooking(response, requestData, postAction, successCallback, origin, booking) {
          $rootScope.bookingsUpdating[requestData.filekey] = false;
          componentHelper.closeSidenavs();

          // wrong branding or lastname, redirect to error page and remove booking
          if (response.data && response.data.meta && [602, 603].includes(response.data.meta.cockpit_code)) {
            postAction = 'redirect';
            removeBooking(requestData.filekey, requestData.lastname, true);
          }

          if (postAction) {
            switch (postAction) {
              case 'redirect':
              case 'redirectSeatmap':
              case 'redirectBaggage':
                if (response.meta && response.meta.success) {
                  if (origin) {
                    $rootScope.origin = origin;
                    $rootScope.bookingSource = response.meta.source;

                    if (['addBookingPage', 'addBookingDialog', 'addBookingIframe','overview', 'welcome'].includes(origin)) {
                      var redirectRoute = postAction === 'redirectSeatmap' ? '/seatmap' :
                        postAction === 'redirectBaggage' ? '/baggage' : '';

                      navigationHelper.redirectWithLocale('/details/:' + requestData.filekey + '/:' + requestData.lastname + redirectRoute);
                    }
                  }
                } else {
                  response.data = response.data || {
                    meta: {
                      cockpit_code: -1
                    }
                  };
                  response.data.filekey = requestData.filekey;
                  response.data.lastname = requestData.lastname;
                  $localStorage.cockpit.error.captcha.displayAddCaptcha = response.data.meta.displayAddCaptcha;
                  $rootScope.error = angular.merge($localStorage.cockpit.error, response.data);
                  navigationHelper.redirectWithLocale('/error');
                }
                break;
              case 'notify':
                var options = {};

                if (booking && !(booking.filekey === $rootScope.activeFilekey || (booking && $filter('isEmpty')(booking.segments)))) {
                  options = {
                    linkPath: '/details/:' + requestData.filekey + '/:' + requestData.lastname,
                    linkText: gettextCatalog.getString("from {{departureAirportCity}} to {{arrivalAirportCity}}, {{dateDisplay}}",
                      {
                        departureAirportCity: booking.departureAirportCity,
                        arrivalAirportCity: booking.arrivalAirportCity,
                        dateDisplay: $filter('dateDisplay')(booking.departureDateTime, booking.arrivalDateTime)
                      }
                    )
                  };
                }

                options.notification = 'bookingRefreshed';

                if (successCallback && response.meta && response.meta.success) {
                  if (booking.cancelled) {
                    options.status = 'cancelled';
                    componentHelper.showNotification('error', null, options);
                  } else {
                    options.status = 'success';
                    componentHelper.showNotification('success', null, options);
                  }
                } else {
                  options.status = 'error';
                  componentHelper.showNotification('error', null, options);
                }
                break;
            }
          }
        }

        function parseBooking(responseData) {
          var bookingsResponse = new JsonApiDataStore();
          bookingsResponse.sync(responseData);
          var bookings = bookingsResponse.findAll("bookings");

          return bookings[0];
        }

        function processBooking(booking) {
          booking.names = bookingHelper.setBookingNames(booking);

          // TODO: COPIT-4846 Careful, external_booking_link is only used for significant schedule changes
          if (booking.externalBookingLink === null) {
            delete booking.externalBookingLink;
          }

          processSegments(booking.segments);
        }

        function processSegments(segmentsData) {
          var segmentPrevious;

          angular.forEach(segmentsData, function (segment) {
            var mDepartureDateTime = moment.utc(segment.departureDateTime);
            var mArrivalDateTime = moment.utc(segment.arrivalDateTime);

            if (segmentPrevious) {
              mArrivalDateTime = moment.utc(segmentPrevious.arrivalDateTime);
              mDepartureDateTime = moment.utc(segment.departureDateTime);
              var transitDuration = moment.duration(mDepartureDateTime.diff(mArrivalDateTime));

              if (segmentHelper.isTrainVoucherSegment(segmentPrevious) || segmentHelper.isTrainVoucherSegment(segment)) {
                segment.transitDurationAsMinutes = null;
              } else {
                segment.transitDurationAsMinutes = transitDuration.asMinutes();
              }

              segment.previousSegmentArrivalAirportCode = segmentPrevious.arrivalAirportCode;
            }

            if (segment.status !== 'cancelled') {
              segmentPrevious = segment;
            }

            segment.transitDurationShow = segment.transitDurationAsMinutes && !(segment.status === 'cancelled' || mDepartureDateTime < mArrivalDateTime);

            angular.forEach(segment.passengers, function (passenger) {
              if (passenger.seatRequest.length === 0) {
                delete passenger.seatRequest;
              } else {
                passenger.seatRequest = passenger.seatRequest[0];
              }

              if (passenger.baggageAllowance.length === 0) {
                delete passenger.baggageAllowance;
              } else {
                passenger.baggageAllowance = passenger.baggageAllowance[0];
              }

              if (passenger.insuranceBaggage.length === 0) {
                delete passenger.insuranceBaggage;
              } else {
                passenger.insuranceBaggage = passenger.insuranceBaggage[0];
              }

              if (passenger.automaticCheckin.length === 0) {
                delete passenger.automaticCheckin;
              } else {
                passenger.automaticCheckin = passenger.automaticCheckin[0];
              }

              if (passenger.boardingPass.length === 0) {
                delete passenger.boardingPass;
              } else {
                passenger.boardingPass = passenger.boardingPass[0];
              }
            });
          });
        }

        function addBooking(booking, origin) {
          if (profileHelper.isLoggedIn()) {
            if (!Object.keys($rootScope.profile.profileBookings).length) {
              $rootScope.profile.profileBookings = {};
            }

            $rootScope.profile.profileBookings[booking.filekey] = booking;
            profileHelper.updateSessionProfile();
          } else {
            $localStorage.cockpit.bookings[booking.filekey] = booking;
          }

          $rootScope.booking = booking;
          $rootScope.activePnrIdentifier = booking.pnrIdentifier;
          $rootScope.activeFilekey = booking.filekey;
          $rootScope.activeLastname = booking.searchName;

          $rootScope.$broadcast('bookingFetchingComplete');

          if (!$rootScope.myCockpitIframe && (booking.filekey === $rootScope.activeFilekey)) {
            var logRequestParams = {
              interactionName: 'bookingAdded',
              filekey: booking.filekey,
              lastname: booking.searchName,
              language: $rootScope.activeLocale
            };

            logsProvider.logInteraction(logRequestParams);
          }

          if (!['addBookingPage', 'addBookingDialog', 'addBookingIframe', 'overview'].includes(origin)) {
            functionalities.getFunctionalities();
          }
        }

        function removeBooking(filekey, lastname, skipLogInteraction, tab) {
          var booking = bookingHelper.getBookings()[filekey];

          if (booking) {
            var options = {
              notification: 'bookingRemoved',
              linkPath: '/details/:' + filekey + '/:' + lastname,
              details: filekey
            };

            if (profileHelper.isLoggedIn()) {
              var requestParams = {
                'pnrIdentifier': booking.pnrIdentifier,
                'filekey': filekey,
                'lastname': lastname
              };

              profile.removeBookingFromProfile(requestParams).then(
                function successCallback(response) {
                  if (response.meta && response.meta.success) {
                    removeBookingFromWebStorage(filekey, lastname, skipLogInteraction, tab, options);
                  } else {
                    options.status = 'error';
                    componentHelper.showNotification('error', null, options);
                  }
                },
                function errorCallback() {
                  options.status = 'error';
                  componentHelper.showNotification('error', null, options);
                }
              );
            } else {
              removeBookingFromWebStorage(filekey, lastname, skipLogInteraction, tab, options);
            }
          }
        }

        function removeBookingFromWebStorage(filekey, lastname, skipLogInteraction, tab, options) {
          try {
            delete $localStorage.cockpit.bookings[filekey];
            delete $localStorage.cockpit.baskets[filekey];
            delete $localStorage.cockpit.hideBasketPopover[filekey];

            if (profileHelper.isLoggedIn()) {
              $rootScope.profile.profileBookings[filekey] = undefined;
              profileHelper.updateSessionProfile();
            }

            $rootScope.booking = undefined;
            $rootScope.basket = undefined;

            $rootScope.$broadcast('bookingDeleted');

            if (!skipLogInteraction) {
              var logRequestParams = {
                interactionName: 'bookingDeleted',
                filekey: filekey,
                lastname: lastname,
                language: $rootScope.activeLocale
              };

              logsProvider.logInteraction(logRequestParams);
            }

            options.status = 'success';
            componentHelper.showNotification('success', null, options);

            if (!$rootScope.isWelcomePage()) {
              tab = tab || $rootScope.tab;
              navigationHelper.bookingOverviewPreCheck(tab);
            }
          } catch (e) {
            options.status = 'error';
            componentHelper.showNotification('error', null, options);
          }
        }

        function cancelBooking(filekey, lastname) {
          var requestData = {
            filekey: filekey,
            lastname: lastname
          };

          return cockpitClient.cancelBooking(requestData);
        }

        function isLocalStorageFull() {
          return ((JSON.stringify($localStorage.cockpit).length * 2) / 1024).toFixed(2) > 4000;
        }

        function clearBookingsFromWebStorage() {
          try {
            if ($localStorage.cockpit) {
              $localStorage.cockpit.bookings = {};
              $localStorage.cockpit.baskets = {};
              $localStorage.cockpit.hideBasketPopover = {};

              if (profileHelper.isLoggedIn()) {
                $rootScope.profile.profileBookings = {};
                profileHelper.updateSessionProfile();
              }
            }

            return true;
          } catch (e) {
            return false;
          }
        }
      }
    ];
  }
]);
